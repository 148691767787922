/*
 Copyright 2005-2008 MySQL AB, 2008 Sun Microsystems, Inc. All rights reserved.
 U.S. Government Rights - Commercial software. Government users are subject
 to the Sun Microsystems, Inc. standard license agreement and applicable
 provisions of the FAR and its supplements. Use is subject to license terms.
 This distribution may include materials developed by third parties.Sun,
 Sun Microsystems, the Sun logo and MySQL Enterprise Monitor are
 trademarks or registered trademarks of Sun Microsystems, Inc. in the U.S.
 and other countries.

 Copyright 2005-2008 MySQL AB, 2008 Sun Microsystems, Inc. Tous droits réservés.
 L'utilisation est soumise aux termes du contrat de licence.Cette
 distribution peut comprendre des composants développés par des tierces
 parties.Sun, Sun Microsystems, le logo Sun et MySQL Enterprise Monitor sont
 des marques de fabrique ou des marques déposées de Sun Microsystems, Inc.
 aux Etats-Unis et dans du'autres pays.
*/
function tree(name, selectedGroupId) {
	var _self = this;
	
	var _tree = null;
    var _selectedGroupId;
	var _cookieName = 'treeControl';
	var _expandedNodeNames = [];
	
	_self.restoreExpansionFromCookie = function() {
		var cookieValue = cookieManager.get(_cookieName);
		if (cookieValue) {
			_expandedNodeNames = unescape(cookieValue).split(",");
		}
		for (var index in _expandedNodeNames) {
            var id = _expandedNodeNames[index];
			var nodeToggle = returnObject(id);
			if (nodeToggle) {
				_self.doToggleChildren(nodeToggle, 'collapsed');
			} else {
				_expandedNodeNames.splice(index, 1);
			}
		}
	};
	
	_self.doToggleChildren = function(which, curState) {
        var nodeThatFiredEvent = which.parentNode;
		var childrenNodes = getFirstElementWithClassName('treeWidgetNodeList', nodeThatFiredEvent, 'div', true);
		if (which.className && typeof(childrenNodes) != 'undefined') {
			if (curState == 'collapsed') {
                $(which).removeClass('nodeSelectorCollapsed').addClass('nodeSelectorExpanded');
                $(childrenNodes).show();
			} else if (curState == 'expanded') {
                $(which).removeClass('nodeSelectorExpanded').addClass('nodeSelectorCollapsed');
                $(childrenNodes).hide();
			}
		}
	};

	// toggle expand/collapse - wraps doToggleChildren so that cookies can restore state without resetting cookies...
	_self.toggleChildren = function(which) {
		var identifier = which.id;
        var match = /tgt(\d+)/.exec(identifier);
        var groupId = -1;
        if (match != null) {
            groupId = match[1];
        }
        
		var curState = 'collapsed';
        if ($(which).hasClass('nodeSelectorCollapsed')) {
			curState = 'collapsed';
			_expandedNodeNames.push(identifier);
		} else {
			curState = 'expanded';
			// try to remove from expanded list
			for (var i = 0; i < _expandedNodeNames.length; i++) {
				if (_expandedNodeNames[i] == identifier) {
					_expandedNodeNames.splice(i, 1);
				}
			}
		}

        if (groupId == _selectedGroupId) {
            var nextState = curState == 'expanded' ? 'collapsed' : 'expanded';
            cookieManager.set(_cookieName + '-selected', _selectedGroupId);
            cookieManager.set(_cookieName + '-selected-state', nextState);
        }

		storeNodeCookie(_expandedNodeNames);
		_self.doToggleChildren(which, curState);
	};
	
	function storeNodeCookie(nodeNames) {
		if (nodeNames.length > 0) {
			var nodeNamesString = nodeNames.join(',');
			cookieManager.set(_cookieName, nodeNamesString);
		} else {
			cookieManager.remove(_cookieName);
		}
	}

    _self.doInit = function() {
		_tree = returnObject(_tree);

        $('div.treeWidgetNode:has(div.treeWidgetNodeList) > span.nodeSelectorTag')
        .click(function() {_self.toggleChildren(this)});

		_self.restoreExpansionFromCookie();

        var expectedSelected = cookieManager.get(_cookieName + '-selected');
        if (_selectedGroupId != expectedSelected) {
            cookieManager.set(_cookieName + '-selected-state', 'expanded');
        } else {
            var existing = cookieManager.get(_cookieName + '-selected-state');
            if (existing == 'collapsed') {
                _self.doToggleChildren(returnObject('tgt'+_selectedGroupId), 'expanded');
            }
        }
	};

    /*
	_self.setTree = function(tree) {
		_tree = tree;
	};
	
	_self.init = function() {
		addDOMLoadListener(_self.doInit);
	};
*/

    _tree = name;
    _selectedGroupId = selectedGroupId;
    $(_self.doInit);

}
;
define("tree", ["default"], function(){});

